import React from 'react';
import Page from 'components/page';
import MenAtWorkView from 'views/men-at-work';

const AnalyticsPage = () => (
    <Page title="Analytics" >
      <MenAtWorkView />
    </Page>
);
export default AnalyticsPage;
